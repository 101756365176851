import { NavLink, useNavigate } from "react-router-dom";
import styles from "./DataTable.module.scss";
import { dateTimeFormatter, truncateString } from "../../helpers/formatter";
import { convertFileSize } from "../../helpers/common";
import { PortalApiUrls } from "../../helpers/api-urls/PortalApiUrls";
import _sendAPIRequest from "../../helpers/api";
import classNames from "classnames";
import DeleteDialog from "../CustomDialog/DeleteDialog";
import { useContext, useState } from "react";
import { AlertContext } from "../../contexts/AlertProvider";
import { Select, MenuItem, FormControl } from "@mui/material";

const patchBidStatus = async (id, formData) => {
  console.log(formData, "actionformdata");
  try {
    const response = await _sendAPIRequest(
      "PATCH",
      `${PortalApiUrls.BID_SAMPLE_ACTION}${id}/`,
      formData,
      true
    );

    if (response.status === 200) {
      console.log(response, "bid sample Action");
      // setAlert({
      //   isVisible: true,
      //   message: "Your Bid Status Updated sucessfully",
      //   severity: "success",
      // });
    }
  } catch (error) {
    // setAlert({
    //   isVisible: true,
    //   message: error?.response?.data?.error || "An unexpected error occurred.",
    //   severity: "error",
    // });
  }
};

const onCloneBidClick = async (id, navigate) => {
  try {
    const response = await _sendAPIRequest(
      "POST",
      `${PortalApiUrls.CLONE_BID}${id}/`,
      null,
      true
    );
    if (response?.status === 201) {
      console.log(response);
      navigate(`/portal/bids/categories/${response.data.id}`);
    }
  } catch (error) {
    console.log("Error cloning bid", error);
  }
};

const CloneConfirmation = ({ id, onCloneConfirm }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloneClick = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = async (confirm) => {
    setOpenDialog(false);
    if (confirm) {
      await onCloneConfirm(id);
    }
  };

  return (
    <>
      <p
        className={styles["table-link"]}
        // style={{ color: "#0d6efd" }}
        style={{
          color: "#0d6efd",
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Ensure vertical alignment
          height: "100%", // Make sure it fills the cell height
          padding: "8px", // Apply uniform padding
          marginBottom: "0",
        }}
        onClick={handleCloneClick}
      >
        Clone Bid
      </p>
      {openDialog && (
        <DeleteDialog
          title="Clone Bid"
          message="Are you sure you want to clone this bid?"
          handleClick={handleDialogClose}
        />
      )}
    </>
  );
};

export const created_bids_column = [
  {
    Header: "Bid ID",
    accessor: "formatted_number",
    align: "left",
    disablePadding: false,
    width: 150, // Add a uniform width
  },
  {
    Header: "Bid Title",
    accessor: "title",
    align: "left",
    disablePadding: false,
    width: 150, // Change to uniform width
    Cell: (data) => {
      return (
        <NavLink
          className={styles["table-link"]}
          to={`/portal/bids/details/${data?.row?.original?.id}`}
        >
          {`${truncateString(data?.row?.original?.title, 30)}${
            data?.row?.original?.type === "L1" ? null : " (QCBS)"
          }`}
        </NavLink>
      );
    },
  },
  {
    Header: "Opening Date",
    accessor: "bid_open_date",
    align: "left",
    disablePadding: false,
    width: 150, // Change to uniform width
    Cell: (data) => {
      return data?.row?.original?.bid_open_date
        ? `${dateTimeFormatter(data?.row?.original?.bid_open_date)}`
        : " - ";
    },
  },
  {
    Header: "Closing Date",
    accessor: "bid_close_date",
    align: "left",
    disablePadding: false,
    width: 150, // Change to uniform width
    Cell: (data) => {
      return data?.row?.original?.bid_close_date
        ? `${dateTimeFormatter(data?.row?.original?.bid_close_date)}`
        : " - ";
    },
  },
  {
    Header: "Status",
    accessor: "status",
    align: "center",
    width: 100, // Change to uniform width

    disablePadding: false,
    hideSortIcon: true,
    Cell: (data) => {
      return (
        <div
          className={`status-cloumn ${data?.row?.original?.status}`}
          style={{
            color: `${
              data?.row?.original?.status === "active"
                ? "#22bb33"
                : "darkyellow"
            }`,
          }}
        >
          {data?.row?.original?.status}
        </div>
      );
    },
  },
  {
    Header: "Action",
    accessor: "clone_bid",
    align: "center", // Center alignment to make it consistent
    width: 100,
    disablePadding: false,
    hideSortIcon: true,
    Cell: (data) => {
      const navigate = useNavigate();
      return (
        <CloneConfirmation
          id={data?.row?.original?.id}
          onCloneConfirm={(id) => onCloneBidClick(id, navigate)}
        />

        // <p
        //   className={styles["table-link"]}
        //   style={{ color: "#0d6efd" }}
        //   onClick={() => onCloneBidClick(data?.row?.original?.id, navigate)}
        // >
        //   Clone Bid
        // </p>
      );
    },
  },
  {
    Header: "Invite",
    accessor: "reserved_price",
    align: "left",
    disablePadding: false,
    paddingLeft: "2rem",
    width: 100, // Change to uniform width
    Cell: (data) => {
      console.log(data?.row?.original?.type); // Debugging/logging

      const isInviteDisabled =
        data?.row?.original?.status !== "active" ||
        (data?.row?.original?.type === "L1" &&
          data?.row?.original?.bid_close_date === null) ||
        (data?.row?.original?.type === "QCBS" &&
          (data?.row?.original?.sample_receive_start_date === null ||
            data?.row?.original?.sample_receive_end_date === null));

      return (
        <NavLink
          style={{ textAlign: "center" }}
          className={
            isInviteDisabled ? styles["disabled-link"] : styles["table-link"]
          }
          to={`/portal/companies/${data?.cell?.row?.original.id}`}
        >
          Invite
        </NavLink>
      );
    },
  },
];

export const invited_bids_column = [
  {
    Header: "Bid ID",
    accessor: "bid.formatted_number",
    align: "left",
    disablePadding: false,
    Cell: (data) => {
      return data.row.original.bid.formatted_number;
    },
  },
  {
    Header: "Bid Title",
    accessor: "title",
    align: "left",
    disablePadding: false,
    width: 150,
    Cell: (data) => {
      return (
        <NavLink
          className={styles["table-link"]}
          to={`/portal/bids/details/${data?.row?.original?.bid?.id}/?type=invited`}
        >
          {truncateString(data?.row?.original?.bid?.title, 30)}
        </NavLink>
      );
    },
  },
  {
    Header: "Company Name",
    accessor: "Company_Name",
    align: "left",
    disablePadding: false,
    width: 150,
    Cell: (data) => {
      return data?.row?.original?.bid?.company?.name;
    },
  },
  {
    Header: "Opening Date",
    accessor: "bid_start_date",
    align: "left",
    disablePadding: false,
    width: 150,
    Cell: (data) => {
      return (
        <>
          {data?.row?.original?.bid?.bid_open_date === null
            ? "-"
            : dateTimeFormatter(data?.row?.original?.bid?.bid_open_date)}
        </>
      );
    },
  },
  {
    Header: "Closing Date",
    accessor: "bid_end_date",
    align: "left",
    disablePadding: false,
    width: 150,
    Cell: (data) => {
      return (
        <>
          {data?.row?.original?.bid?.bid_close_date === null
            ? "-"
            : dateTimeFormatter(data?.row?.original?.bid?.bid_close_date)}
        </>
      );
    },
  },
  {
    Header: "Status",
    accessor: "status",
    align: "center",
    disablePadding: false,
    hideSortIcon: true,
    width: 150,
    Cell: (data) => {
      console.log(data); // Debugging log

      return (
        <div
          className={`status-column ${data?.row?.original?.status}`}
          style={{
            color: `${
              data?.row?.original?.status === "accepted"
                ? "#22bb33" // Green for accepted
                : data?.row?.original?.status === "pending"
                ? "#FFBF00" // Yellow for pending
                : "red" // Default red for other statuses
            }`,
          }}
        >
          {data?.row?.original?.status}
        </div>
      );
    },
  },

  // {
  //   Header: "Action",
  //   accessor: "action",
  //   align: "center",
  //   disablePadding: false,
  //   hideSortIcon: true,
  //   width: 150,
  //   Cell: (data) => {
  //     // return (
  //     //   <div className={`status-cloumn ${data?.row?.original?.bid?.status}`}>
  //     //     {data?.row?.original?.bid?.status}
  //     //   </div>
  //     // );
  //   },
  // },
];

export const related_bids_column = [
  {
    Header: "Bid ID",
    accessor: "formatted_number",
    align: "left",
    disablePadding: false,
  },
  {
    Header: "Bid Title",
    accessor: "title",
    align: "left",
    disablePadding: false,
    width: 200,
    Cell: (data) => {
      return (
        <NavLink
          className={styles["table-link"]}
          to={`/portal/bids/details/${data?.row?.original?.id}`}
        >
          {truncateString(data?.row?.original?.title, 30)}
        </NavLink>
      );
    },
  },
  {
    Header: "Opening Date",
    accessor: "bid_open_date",
    align: "left",
    disablePadding: false,
    width: 190,
    Cell: (data) => {
      // return dateTimeFormatter(data?.row?.original?.bid_open_date);
      return data?.row?.original?.bid_open_date
        ? `${dateTimeFormatter(data?.row?.original?.bid_open_date)}`
        : " - ";
    },
  },
  {
    Header: "Closing Date",
    accessor: "bid_close_date",
    align: "left",
    disablePadding: false,
    width: 190,
    Cell: (data) => {
      return data?.row?.original?.bid_close_date
        ? `${dateTimeFormatter(data?.row?.original?.bid_close_date)}`
        : " - ";
    },
  },
  // {
  //   Header: "Reserve Price",
  //   accessor: "reserved_price",
  //   align: "right",
  //   disablePadding: false,
  //   Cell: (data) => {
  //     return `₹ ${data.row.original.reserved_price}`;
  //   },
  // },
  {
    Header: "Status",
    accessor: "status",
    align: "center",
    disablePadding: false,
    hideSortIcon: true,
    Cell: (data) => {
      return (
        <div className={`status-cloumn ${data?.row?.original?.status}`}>
          {data?.row?.original?.status}
        </div>
      );
    },
  },
  {
    Header: "Action",
    accessor: "clone_bid",
    align: "right",
    disablePadding: false,
    // width: 300,
    Cell: (data) => {
      return (
        <NavLink
          className={styles["table-link"]}
          to={`/portal/bids/update/${data?.row?.original?.id}`}
        >
          Clone Bid
        </NavLink>
      );
    },
  },
];

export const documents_column = [
  {
    Header: "Document Name",
    accessor: "file_name",
    align: "left",
    disablePadding: false,
    width: 160,
  },
  {
    Header: "Document Type",
    accessor: "type",
    align: "left",
    disablePadding: false,
    width: 160,
    Cell: (data) => {
      const handlePreviewDocument = (data) => {
        const { file } = data; // File URL
        const link = document.createElement("a");
        link.href = file;
        link.target = "_blank"; // Opens in new tab
        link.rel = "noopener noreferrer"; // Security enhancement
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      };
      return (
        <div
          className={styles["document-type"]}
          onClick={() => handlePreviewDocument(data.row.original)}
        >
          {data?.row?.original?.type}
        </div>
      );
    },
  },
  {
    Header: "Document Size",
    accessor: "size",
    align: "left",
    disablePadding: false,
    width: 160,
    Cell: (data) => {
      return convertFileSize(data?.row?.original?.size);
    },
  },
  {
    Header: "Document Date",
    accessor: "created_at",
    align: "left",
    disablePadding: false,
    width: 180,
    Cell: (data) => {
      return dateTimeFormatter(data?.row?.original?.created_at);
    },
  },
  {
    Header: "Action",
    accessor: "action",
    align: "center",
    disablePadding: false,
    width: 100,
    hideSortIcon: true,
  },
];

export const companies_column = [
  {
    Header: "Company Name",
    accessor: "name",
    align: "left",
    disablePadding: false,
    width: 160,
  },
  {
    Header: "Company Email",
    accessor: "business_email",
    align: "left",
    disablePadding: false,
    width: 160,
  },
  {
    Header: "Company Mobile",
    accessor: "business_mobile",
    align: "left",
    disablePadding: false,
    width: 180,
  },
  {
    Header: "Action",
    accessor: "action",
    align: "center",
    disablePadding: false,
    width: 100,
    hideSortIcon: true,
  },
];

export const l1_participants_column = [
  {
    Header: "Company Name",
    accessor: "company.name",
    align: "left",
    disablePadding: false,
    width: 160,
    Cell: (data) => {
      return data.row.original.company.name;
    },
  },
  {
    Header: "Company Email",
    accessor: "company.business_email",
    align: "left",
    disablePadding: false,
    width: 160,
  },
  {
    Header: "Company Mobile",
    accessor: "company.business_mobile",
    align: "left",
    disablePadding: false,
    width: 160,
  },
  {
    Header: "Status",
    accessor: "status",
    align: "left",
    disablePadding: false,
    hideSortIcon: true,
    Cell: (data) => {
      return (
        <>
          <div
            className={`status-cloumn ${
              data?.row?.original?.status === "accepted"
                ? "success"
                : data?.row?.original?.status === "pending"
                ? "pending"
                : "cancelled"
            }`}
          >
            {data?.row?.original?.status}
          </div>
        </>
      );
    },
  },
  {
    Header: "Action",
    accessor: "action",
    align: "center",
    disablePadding: false,
    width: 100,
    hideSortIcon: true,
  },
];

export const products_Column = ({
  setShowSpecification,
  setSelectedProduct,
}) => [
  {
    Header: "Product Title",
    accessor: "title",
    align: "left",
    disablePadding: false,
    width: 160,
    Cell: (data) => {
      return data.row.original.title;
    },
  },
  {
    Header: "Quantity",
    accessor: "quantity",
    align: "left",
    disablePadding: true,
    width: 160,
  },
  {
    Header: "Reserve Price",
    accessor: "reserved_price",
    align: "left",
    disablePadding: false,
    width: 160,
  },
  {
    Header: "Specification",
    accessor: "specification",
    align: "left",
    disablePadding: false,
    width: 160,
    Cell: (data) => {
      return (
        <NavLink
          className={styles["table-link"]}
          onClick={() => {
            setSelectedProduct(data.row.original);
            setShowSpecification(true);
          }}
        >
          View Speciication
        </NavLink>
      );
    },
  },
];

export const PreviousBids_column = [
  {
    Header: "Product Name",
    accessor: "product name",
    align: "left",
    disablePadding: false,
    width: 160,
  },
  {
    Header: "Quantity",
    accessor: "quantity",
    align: "left",
    disablePadding: false,
    width: 160,
  },
  {
    Header: "Unit",
    accessor: "unit",
    align: "left",
    disablePadding: false,
    width: 160,
    Cell: (data) => {
      return convertFileSize(data?.row?.original?.size);
    },
  },
  {
    Header: "Closing Date",
    accessor: "closing date",
    align: "left",
    disablePadding: false,
    width: 180,
    Cell: (data) => {
      return dateTimeFormatter(data?.row?.original?.created_at);
    },
  },
  {
    Header: "Buyer’s Rating",
    accessor: "buyer’s rating",
    align: "center",
    disablePadding: false,
    width: 100,
    hideSortIcon: true,
  },
];

export const Sample_Bid_Invitations_column = [
  {
    Header: "Company Name",
    accessor: "company_Name",
    align: "left",
    disablePadding: false,
    width: 150, // Add a uniform width
    Cell: (data) => {
      return `${data.row.original.company.name}`;
    },
  },
  {
    Header: "Sample Status",
    accessor: "company_email",
    align: "left",
    disablePadding: false,
    width: 150, // Add a uniform width
    Cell: (data) => {
      const [status, setStatus] = useState(
        data.row.original.sample.is_received
      );
      console.log(data.row.original.sample.is_received, "is_received");

      const handleStatusChange = (event) => {
        const newStatus = event.target.value;
        setStatus(newStatus);

        const formData = {
          is_received: newStatus,
        };
        patchBidStatus(data.row.original.id, formData);
      };

      return (
        <FormControl sx={{ minWidth: 120, maxWidth: 150 }} size="small">
          <Select
            value={status}
            onChange={handleStatusChange}
            style={{
              color: status === "true" ? "green" : "red",
              height : "35px",
              fontSize : "14px" // Conditional color for the select box
            }}
          >
            <MenuItem value="true" style={{ color: "green" }}>
              Received
            </MenuItem>
            <MenuItem value="false" style={{ color: "red" }}>
              Not Received
            </MenuItem>
          </Select>
        </FormControl>
      );
    },
  },
  {
    Header: "Action",
    accessor: "mobile_number",
    align: "left",
    disablePadding: false,
    width: 150, // Add a uniform width
    Cell: (data) => {
      const [status, setStatus] = useState(
        data.row.original.sample.approval_status === "rejected"
          ? "reject"
          : data.row.original.sample.approval_status === "pending"
          ? "pending"
          : "approve"
      );
      console.log(data.row.original.sample.approval_status, "approval_status");

      const handleStatusChange = (event) => {
        const newActionStatus = event.target.value;
        setStatus(newActionStatus);

        const formData = {
          action: newActionStatus,
        };
        patchBidStatus(
          data.row.original.id,
          formData,
          true,
          data.row.original.company.id
        );
      };

      return (
        <FormControl sx={{ minWidth: 120, maxWidth: 150 }} size="small">
          <Select
            disabled={status === "approve"}
            value={status}
            onChange={handleStatusChange}
            sx={{
              height: "35px", // Adjust the height of the dropdown
              fontSize: "14px", // Adjust the font size inside the dropdown
            }}
          >
            {status === "pending" && (
              <MenuItem value="pending" disabled sx={{ color: "yellow" }}>
                Pending
              </MenuItem>
            )}
            <MenuItem value="approve" sx={{ color: "green" }}>
              Approved
            </MenuItem>
            <MenuItem value="reject" sx={{ color: "red" }}>
              Not Approved
            </MenuItem>
          </Select>
        </FormControl>
      );
    },
  },
];
export const Sample_Bid_Invitations_result_log = [
  {
    Header: "Company Name",
    accessor: "company.name",
    align: "left",
    disablePadding: false,
    width: 160,
    Cell: (data) => {
      return data.row.original.company.name;
    },
  },
  {
    Header: "Company Email",
    accessor: "company.business_email",
    align: "left",
    disablePadding: false,
    width: 160,
  },
  {
    Header: "Company Mobile",
    accessor: "company.business_mobile",
    align: "left",
    disablePadding: false,
    width: 160,
  },
  {
    Header: "Status",
    accessor: "status",
    align: "left",
    width: 150, // Change to uniform width
    disablePadding: false,
    hideSortIcon: true,
    Cell: (data) => {
      console.log("data : ", data);
      return (
        <div
          className={`status-cloumn ${data?.row?.original?.sample?.invite_status}`}
          style={{
            color: `${
              data?.row?.original?.sample?.invite_status === "accepted"
                ? "#22bb33"
                : "darkyellow"
            }`,
          }}
        >
          {data?.row?.original?.sample?.invite_status}
        </div>
      );
    },
  },
];
