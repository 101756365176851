import styles from './Chat.module.scss'

const Chat = () => {
  return (
    <>

    </>
  )
}

export default Chat